@tailwind base;
@tailwind components;
@tailwind utilities;

:focus:not(:focus-visible) {
  outline: none;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bgimg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  overflow: hidden;
  z-index: 0;
  filter: blur(4px);
  pointer-events: none;
}

.blur-xs {
  filter: blur(3px);
}
.blur-xxs {
  filter: blur(0.5px);
}

@keyframes animate-in-blur {
  0% {
    filter: blur(1px);
  }
}
.animate-in-blur {
  animation: name duration timing-function delay iteration-count direction fill-mode;
}
.text-xxs {
  font-size: 0.6rem;
}

input[type="checkbox"] {
  position: relative;
  /* width: 1.2em;
  height: 1.2em; */
  color: #363839;
  border: 1px solid #bdc1c6;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: all 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

input[type="checkbox"]::before {
  position: absolute;
  content: "";
  display: block;
  top: 45%;
  left: 52%;
  width: 40%;
  height: 70%;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
}

input[type="checkbox"]:checked {
  color: #fff;
  border-color: #1e4bff;
  background: #1e4bff;
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

input[type="checkbox"]:checked ~ label::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.text-loadable {
  transition: all 0.2s 0.1s ease;
}

/* todo: make text gradient animation */
.text-loading {
  color: gray;
}

.transition-delay {
  transition-delay: 0.06s;
}

.bg-modal-dim {
  background-color: #00000096;
}

.track-con:hover .plus {
  opacity: 0.5 !important;
}

.plus:hover {
  opacity: 1 !important;
}

.sysfont {
  font-family: -apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

.no-bottom-rounded {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 5px;
  text-indent: -9999em;
  border-top: 0.8em solid rgba(255, 255, 255, 0.2);
  border-right: 0.8em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.8em solid rgba(255, 255, 255, 0.2);
  border-left: 0.8em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.styles_type__2Teeh::after {
  transition: color 0.1s ease;
}

.hide-cursor .styles_type__2Teeh::after {
  color: #121212;
}

.t-section {
  height: auto;
}

.t-section.full {
  height: 80vh;
  display: block;
  box-sizing: border-box;
  padding: 1vh 0px;
  margin-top: 0;
}
.t-section.right {
  text-align: right;
}
.demo-section {
  /* background: linear-gradient(180deg, #121212 calc(100% - 40px), transparent); */
  padding: 60px 0px;
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .t-section {
    height: 80vh;
  }

  .t-section:last-of-type {
    height: 80vh;
  }

  .t-section.full {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.frf-trigger-button {
  background: rgba(255, 255, 255, 0.26) !important;
}
.frf-modal-input {
  color: black;
}

.text-shadow {
  text-shadow: 0 0 5px #0000008a;
}
.frf-feedback-container {
  z-index: 100 !important;
}

.shimmer-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  z-index: 100;
  pointer-events: none;
}
.shimmer-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: -100%;
  bottom: 0;
  left: -100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 33.3%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 66.6%
  );
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  from {
    transform: translateX(-33.3%);
  }
  to {
    transform: translateX(33.3%);
  }
}

.filter-container {
  @apply relative;
}
.filter-input {
  background: #3f3f3f;
  border-radius: 6px;
  padding: 20px 12px;
  color: white;
  font-size: 14px;
  display: block;
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  width: 100%;

  outline-width: 0;
  outline-style: solid;
  outline-color: #55b7ffab;

  transition: outline 0.2s ease;
}
.filter-input:focus {
  outline-width: 0.15rem !important;
}

.filter-input::placeholder {
  color: #9f9f9f;
}
.close.clear-filter {
  @apply absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer;
}

.multi-email-input input {
  background: #3f3f3f;
  color: white;
  padding: 0 1rem;
  width: 200px !important;
}
.multi-email-input.react-multi-email > span[data-placeholder] {
  position: static !important;
  font-size: 14px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  transform: scale(0.99) translateY(10px);
  transition: all 300ms ease 100ms;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  transform: none;
  opacity: 1;
  transition: all 300ms ease;
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  transform: scale(0.9);
  opacity: 0;

  transition: all 300ms ease;
}
